import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";
import { useUser } from "@auth0/nextjs-auth0";
import { useRouter } from "next/router";

import analytics from "../lib/analytics";
import { identify as logRocketIdentify } from "../lib/logrocket";

type Props = {
  title: string;
  white?: boolean;
};

const Layout: React.FunctionComponent<Props> = ({ children, title, white }) => {
  const { user } = useUser();
  const { pathname } = useRouter();
  useEffect(() => {
    if (user) {
      const userId = `${user.sub}`;
      analytics.identify(userId, user);
      logRocketIdentify(userId, user.name, user.name);
      Sentry.setUser({
        id: userId,
        email: user.email,
        name: user.name,
      });
    }
  }, [user]);

  return (
    <>
      <Head>
        <title>Grizzly Challenge | {title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar bg="light" expand="lg">
        <Container>
          <Link passHref href="/">
            <Navbar.Brand className="d-inline-flex align-items-center">
              <Image alt="logo" src="/logo.png" width={32} height={36.7812} />
              <span className="ms-2 mt-1">Grizzly Challenge</span>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Link passHref href="/">
                <Nav.Link active={pathname == "/"}>Home</Nav.Link>
              </Link>
              <Link passHref href="/about">
                <Nav.Link active={pathname == "/about"}>About</Nav.Link>
              </Link>
              <Link passHref href="/prizes">
                <Nav.Link active={pathname == "/prizes"}>Prizes</Nav.Link>
              </Link>
              <Link passHref href="/faq">
                <Nav.Link active={pathname == "/faq"}>FAQ</Nav.Link>
              </Link>
              <Link passHref href="/contact">
                <Nav.Link active={pathname == "/contact"}>Contact</Nav.Link>
              </Link>
              {user && (
                <Link passHref href="/dashboard">
                  <Button className="ms-3" variant="primary">
                    Dashboard
                  </Button>
                </Link>
              )}
              {!user && (
                <Button href="/api/auth/login" as="a" variant="outline-primary">
                  Sign In
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="py-4">
        <div
          className={`p-5 mb-4 rounded-3 ${white ? "bg-white" : "bg-light"}`}
        >
          <Container fluid className="py-5">
            {children}
          </Container>
        </div>
      </Container>
    </>
  );
};

export default Layout;
