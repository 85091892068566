import Image from "next/image";
import { NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";

import HomeLayout from "../components/HomeLayout";

const HomePage: NextPage = () => {
  const router = useRouter();
  const [dialogVisible, setDialogVisibility] = useState(false);

  const onHide = () => setDialogVisibility(false);
  const onSignup = () => {
    setDialogVisibility(true);
  };
  return (
    <HomeLayout title="Home">
      <Modal show={dialogVisible} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you are a parent or guardian?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you are only a sponsor please contact a parent or guardian that
            has already signed up and ask for their sponsor link.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              router.push("/api/auth/login?screen_hint=signup");
              onHide();
            }}
          >
            Register as Parent/Guardian
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 className="display-5 fw-bold">Welcome to Grizzly Challenge</h1>
      <Row className="mt-3">
        <Col md="6" className="fs-4 mb-4">
          <strong>Students</strong>– make sure you have an adult with you who
          can help you sign-up for a family account where you can start logging
          minutes and share a sponsor link to friends and family. Sponsors will
          then be able to follow that link, set up their own, separate account
          where they can decide how much money per minute they will pledge
          towards your student&apos;s goals.
          <div className="mt-4 d-flex">
            <div>
              <Button className="me-3" variant="primary" onClick={onSignup}>
                Register as a Parent/Guardian
              </Button>
            </div>
            <div className="mb-3">
              <Link href="/faq?question=sponsor" passHref>
                <Button variant="outline-primary">
                  How do I participate as a Sponsor?
                </Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div>
            <Image alt="" src="/splash.png" width="600" height="561" />
          </div>
        </Col>
      </Row>
    </HomeLayout>
  );
};

export default HomePage;
